import React from "react"
import DarkLayout from "../layout/dark-default"
import SectionClients from "../components/organism/home/sectionClients"
import SectionProjects from "../components/organism/home/sectionProjects"
import SectionIntro from "../components/organism/home/sectionIntro"
import SectionServices from "../components/organism/home/sectionServices"
import SectionAbout from "../components/organism/home/sectionAbout"
import Contacts from "../components/organism/contact/contact.organism"
import { ParallaxProvider } from "react-scroll-parallax"
import "../dist/css/home-custom.css"
import "../dist/css/project-list-custom.css";
import { useEffect } from "react"
import { useState } from "react"
import db from "../firebase"
import SEO from "../layout/SEO"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

const Home = () => {
  const [HomeData, setHome] = useState([]);
  const [imageURL, setImageURL] = useState([]);
  const [imageURL2, setImageURL2] = useState([]);
  const storage = getStorage();
  const images = [];

  useEffect(() => {
    db.collection('Home').onSnapshot(snapshot => {
      setHome(snapshot.docs.map(doc => ({
        id: doc.id,
        about_img: doc.data().about_img,
        banner_img: doc.data().banner_img,
        canonical_url: doc.data().canonical_url,
        meta_desc: doc.data().meta_desc,
        meta_title: doc.data().meta_title,
        og_image: doc.data().og_image,
        product_gallery_images: doc.data().product_gallery_images,
        title: doc.data().title
      })))
    })
  }, [])

  useEffect(() => {
    images.push(HomeData[0] && HomeData[0].about_img);
    images.push(HomeData[0] && HomeData[0].banner_img);
    images.push(HomeData[0] && HomeData[0].product_gallery_images);
    images.map(image => {
      if (typeof image === "string") {
        const imagaRef = ref(storage, image);
        setTimeout(() => {
          getDownloadURL(imagaRef).then(url => {
            setImageURL((prev) => [...prev, url]);
          })
        }, 10);
      }
    })
  }, [HomeData])

  useEffect(() => {
    HomeData[0] && HomeData[0].product_gallery_images.map(image => {
      const imagaRef = ref(storage, image);
      setTimeout(() => {
        getDownloadURL(imagaRef).then(url => {
          setImageURL2((prev) => [...prev, url]);
        })
      }, 100);
    })
  }, [HomeData])

  // console.log(HomeData, "HomeData");

  return (
    <>
      {HomeData[0] ? <SEO canonicalUrl={HomeData[0].canonical_url} title={HomeData[0].title} meta_desc={HomeData[0].meta_desc} /> : null}
      <ParallaxProvider>
        <DarkLayout>

          <SectionIntro featuredImage={imageURL[0]} />
          <SectionServices />
          <SectionAbout homeaboutimage={imageURL[1]} />

          <SectionProjects images={imageURL2} />
          <SectionClients />
          <Contacts dark={true} />
        </DarkLayout>
      </ParallaxProvider>
    </>
  )
}

export default Home