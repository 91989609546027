import React from "react"
import styled from "styled-components";
import { faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from "gatsby"

const SCard=styled.div``

const Card =({data, title, slug})=>{
  const prodtitle= [
    "Glass Mosaics",
    "Ceramic Mosaics",
    "Stone Mosaic",
    "Borders & Murals"
  ]
  return(
    <SCard  className="swiper-slide"><Link className="figure-portfolio figure-portfolio-item_hover" to={`/project`} data-pjax-link="flyingHeading">
      <div className="figure-portfolio__wrapper-img">
        <div className="lazy"><img  src={data}  width="1200" height="1800" alt="" /></div>
          {/* <div className="figure-portfolio__content bg-dark-3">
            <div className="figure-portfolio__category"></div>
            <div className="figure-portfolio__header">
            <h3 className="figure-portfolio__heading split-text js-text-to-fly js-split-text" data-split-text-type="lines, words, chars" data-split-text-set="chars">{title}</h3>
            <div className="figure-portfolio__icon material-icons"><FontAwesomeIcon icon={faAngleRight} color="grey" /></div>
          </div>
          <div className="figure-portfolio__curtain bg-dark-3"></div>
          </div> */}
        </div>
      </Link>
    </SCard>  
  )
}
export default Card