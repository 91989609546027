import React from "react"
import styled from "styled-components"
const Map = styled.section`
  height: 75vh;
  .map {
    filter: invert() grayscale();
  }
`
const SectionMap = ({nomarginTop}) => {
  return (
    <Map
      className={`section section-map bg-white ${nomarginTop ? "" :"section_mt-small"} section_h-800` }
      style={{ paddingTop: "0px" }}
    >
      <iframe
        // className={`${dark ? "map" : null}`}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.6472085499445!2d77.51564841508008!3d28.520260182462152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d85a1571fc5%3A0xe052e8f618a221d4!2sGlastone%20Mosaic%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1640787972586!5m2!1sen!2sin"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: "0" }}
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </Map>
  )
}
export default SectionMap
