import React from "react";
import InputBox from "../../atom/input-box"
import TextArea from "../../atom/textarea"


const SectionForm=({dark,yesmarginTop})=>{
  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

const handleSubmit = (event) => {
    console.log("event",event)
    event.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contactForm",
        "name": event.target.elements.name.value,
        "email": event.target.elements.email.value,
        "phone": event.target.elements.phone.value,
        "msg": event.target.elements.msg.value,
      })
    }).then(() => {document.getElementById("myForm").reset()}).catch(error => alert(error))
  }

  return(
    <div className={`${dark?"bg-dark-2":"bg-white"}`}>
      <section className="section section-form text-center section_pb-small section-offset_top">
        <div className={`${yesmarginTop ? "section_mt-small contact-form-mt" :""} container border-radius section-offset__content bg-dark-3`}>
          <div className="row justify-content-center">
            <div className="col-sm-10">
               <form name="contactForm" id="myForm" data-netlify="true" onSubmit={handleSubmit} netlify={true} className="form form-contact js-ajax-form" netlify method="POST" data-message-success="Thanks for your message! We will reply you as soon as possible." data-message-error="Something went wrong ">
                <h3 className="form__heading">Get in Touch with Us!</h3>
                <InputBox
                  name="name"
                  type="text"
                  label="Name"
                  errorMsg="Name cannot be empty"
                />
                 <InputBox 
                  name="email"
                  type="email"
                  label="Email"
                  errorMsg="Enter valid Email Address"
                />
                 <InputBox 
                  name="phone"
                  type="tel"
                  label="Phone"
                  errorMsg="Enter valid mobile number"
                />
                <TextArea
                  name="msg"
                  label="Your Message"
                />
                <div className="row form__row">
                  <div className="col form__col form__col_submit">
                    <button className="button button_solid button_accent-primary-4 button_fullwidth" type="submit">Send Message</button>
                  </div>
                </div>
              </form> 
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}


export default SectionForm;